
const createCookiesPolicy = (template, url) => {

  const policyElement = document.createElement('div');
  policyElement.classList.add('cookies');
  policyElement.innerHTML = template;
  const cookiesAccept = policyElement.querySelector('.cookies__accept');

  const request = (url) => {
    cookiesAccept.classList.remove('cookies__accept--error');
    const showRequestError = () => {
      cookiesAccept.classList.add('cookies__accept--error');
      setTimeout(() => {
        cookiesAccept.classList.remove('cookies__accept--error');
      }, 1000);
    }

    fetch(url)
    .then((response) => {
      if (response.status === 200) {
        policyElement.remove();
      } else {
        showRequestError();
      }
    })
    .catch(() => {
      showRequestError();
    });

  };

  cookiesAccept && cookiesAccept.addEventListener('click', (evt) => {
    evt.preventDefault();
    request(url);
  });

  return policyElement;
};

const addElementOnPage = (element, parent, insertBefore) => {
  parent.insertBefore(element, parent.firstChild);

  // if (insertBefore) {
  //   parent.insertBefore(element, insertBefore);
  // } else {
  //   parent.appendChild(element);
  // }
};

const renderCookiePolicy = (template) => {
  const url = 'accept-cookies-policy.php';
  const cookiePolicyElement = createCookiesPolicy(template, url);
  const headerElement = document.querySelector('.page-header');
  addElementOnPage(cookiePolicyElement, document.body, headerElement);
  document.body.style.paddingBottom = `${cookiePolicyElement.offsetHeight}px`;
};

window.renderCookiePolicy = renderCookiePolicy;
